import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/hero.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/hero1.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/klarna-banner.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/lunda.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/maskros.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/section1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/section2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/signature.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/uppsala.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default","EmblaSlide"] */ "/vercel/path0/src/modules/common/components/carousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/common/components/countdown/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/modules/home/components/newsletter/index.tsx");
